import React, { ReactElement, useEffect, useState } from "react";
import { Container, Col, Row, Table } from "react-bootstrap";
import { LoadingOverlay } from "../../components/shared";
import { getProjectDetails, getWeatherData } from "../../../helpers/api";
import { useParams } from "react-router-dom";
import weatherBackground from "../../../assets/img/weatherBackground.png";
import thermometer from "../../../assets/icons/thermometer.png";
import WeatherStatsCard from "./components/WeatherStatsCard";
import { TimeSeriesData } from "../../ts/types";
import { LuClock3 } from "react-icons/lu";
import { FaWind } from "react-icons/fa";
import { FaUmbrella } from "react-icons/fa";
import { FaCloud } from "react-icons/fa";
import { FaWater } from "react-icons/fa";

interface WeatherUnits {
  air_pressure_at_sea_level: string;
  air_temperature: string;
  cloud_area_fraction: string;
  precipitation_amount: string;
  relative_humidity: string;
  wind_from_direction: string;
  wind_speed: string;
}

interface WeatherComponentProps {
  refreshingWeather: boolean;
  resetRefresh: () => void;
  weatherInformation: (data: any) => any;
}

const Weather: React.FC<WeatherComponentProps> = ({
  refreshingWeather,
  resetRefresh,
  weatherInformation,
}) => {
  const [uploading, setUploading] = useState(false);

  const [location, setLocation] = useState({ latitude: 0, longitude: 0, altitude: 0 });
  const [weatherData, setWeatherData] = useState<{}>([]);
  const [weatherDataArray, setWeatherDataArray] = useState<any[]>([]);
  const [todaysData, setTodaysData] = useState<TimeSeriesData>();

  const [currentWeather, setCurrentWeather] = useState<TimeSeriesData | undefined>(undefined);

  const [actualTime, setActualTime] = useState<string>(""); // the actual time to display is in utc so we need to display it in nepali time so +5:45

  function convertUTCToNepalTime(utcTime: string): string {
    const date = new Date(utcTime); // Convert UTC string to Date object
    date.setMinutes(date.getMinutes() + 345); // Add 5 hours 45 minutes (345 minutes)

    // Extract hours and format it to always be two digits
    const hours = date.getUTCHours().toString().padStart(2, "0");

    // Set minutes to zero, and always display as "00"
    const minutes = "00";

    return `${hours}:${minutes}`;
  }

  const { projectId } = useParams<{ projectId: string }>();

  //format date into the "5 Aug" format
  const formatDateSingleDigitDay = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-GB", { month: "short" }).format(date);
    return `${day} ${month}`;
  };

  const formatDayAndTime = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-GB", { month: "long" }).format(date);
    return `${day} ${month}`;
  };

  // format time in 18:00 format
  const timeFormat = (initialTime: string) => {
    const time = new Date(initialTime).toISOString().split("T")[1].split(":").slice(0, 2).join(":");
    return time;
  };

  // filter unique dates
  const targetTime = "12:00";

  const filterWeatherData = (timeseries: any) => {
    const dateSet = new Set();
    const filteredData: any = [];

    timeseries.forEach((weather: any) => {
      const date = new Date(weather.time);
      const hours = date.getUTCHours().toString().padStart(2, "0");
      const minutes = date.getUTCMinutes().toString().padStart(2, "0");
      const time = `${hours}:${minutes}`;

      if (time === targetTime) {
        const dateOnly = date.toISOString().split("T")[0];
        if (!dateSet.has(dateOnly)) {
          dateSet.add(dateOnly);
          filteredData.push(weather);
        }
      }
    });

    return filteredData.slice(0, 6);
  };

  const todaysWeatherData = (timeSeriesData: any) => {
    const today = new Date().toISOString().split("T")[0];
    return timeSeriesData?.properties?.timeseries?.filter((entry: any) => {
      // Extract date from time string
      const entryDate = entry.time.split("T")[0];
      return entryDate === today;
    });
  };

  function getCurrentDateTimeWithZeroedMinutesSecondsInUTC() {
    const utcDate = new Date(Date.now()); // Get current UTC time
    utcDate.setUTCMinutes(0, 0, 0); // Set minutes, seconds, and milliseconds to 0
    return utcDate.toISOString().replace(".000", "");
  }

  function getCurrentClosestWeatherData(data: any) {
    const adjustedUTCDateTime = getCurrentDateTimeWithZeroedMinutesSecondsInUTC();
    // compare the current utc date time with the result from weather and return matched value
    return data?.properties?.timeseries?.find((weather: any) => {
      return adjustedUTCDateTime === weather.time;
    });
  }

  const convertUTCNepalTime = (utcTime: string) => {
    const nepalTimeOffset = 5 * 60 + 45; // Nepal is UTC +5:45
    const utcDate = new Date(utcTime); // Convert the string to a Date object
    const nepalTime = new Date(utcDate.getTime() + nepalTimeOffset * 60 * 1000); // Add Nepal time offset
    return nepalTime;
  };

  // Filter data where the time corresponds to 11 PM Nepal Time
  const filterDataForNepalTime = (hourlyData: any[]) => {
    const nepalTimeOffset = 5 * 60 + 45; // Nepal is UTC +5:45
    const today = new Date(); // Get today's date
    const todayNepalTime = new Date(today.getTime() + nepalTimeOffset * 60 * 1000); // Convert today's date to Nepal Time
    const todayDate = todayNepalTime.toISOString().split("T")[0]; // Extract today's date in 'YYYY-MM-DD' format

    return hourlyData.filter((item) => {
      const nepaliTime = convertUTCNepalTime(item.time); // Convert the UTC time to Nepal time
      const itemDate = nepaliTime.toISOString().split("T")[0]; // Extract the date in 'YYYY-MM-DD' format
      const itemHour = nepaliTime.getHours(); // Get the hour in Nepal time

      // Only include data for today, and up until 11 PM
      return itemDate === todayDate && itemHour <= 23;
    });
  };

  const filteredWeatherData = (weatherDataArray[0]?.timelines?.daily || []).map((day: any) => ({
    temperature: {
      avg: day.values.temperatureAvg,
      min: day.values.temperatureMin,
      max: day.values.temperatureMax,
    },
    cloudCover: {
      avg: day.values.cloudCoverAvg,
    },
    humidity: {
      avg: day.values.humidityAvg,
    },
    precipitation: {
      avg: day.values.precipitationProbabilityAvg,
    },
    windSpeed: {
      avg: day.values.windSpeedAvg,
    },
    time: day.time,
  }));

  useEffect(() => {
    setUploading(true);
    const fetchProjectDetails = async () => {
      try {
        const res = await getProjectDetails(projectId);
        setLocation({
          latitude: res.data.latitude,
          longitude: res.data.longitude,
          altitude: res.data.altitude,
        });
      } catch (error) {
        console.error("Error fetching project details:", error);
      }
    };

    fetchProjectDetails();
    setUploading(false);
  }, [projectId]);

  const stopLoading = () => {
    setTimeout(() => {
      resetRefresh();
    }, 500);
  };

  useEffect(() => {
    setUploading(true);
    if (location && location.latitude !== 0 && location.longitude !== 0) {
      const { latitude, longitude, altitude } = location;

      const fetchWeatherData = async () => {
        try {
          const res = await getWeatherData(latitude, longitude, altitude);

          setCurrentWeather({
            data: {
              timelines: {
                minutely: res.data.timelines.minutely ?? [], // Fallback to an empty array
              },
            },
          } as TimeSeriesData);
          setWeatherDataArray([res.data]);
          // const currentWeatherData = todaysWeatherData(res.data.timelines.minutely);
          setTodaysData({
            data: {
              timelines: {
                hourly: filterDataForNepalTime(res.data.timelines.hourly ?? []), // Fallback to an empty array
              },
            },
          } as TimeSeriesData);
        } catch (error) {
          console.error("Error fetching weather data:", error);
        }
      };
      fetchWeatherData();
      setUploading(false);
      stopLoading();
    }
  }, [location, refreshingWeather]);

  // Extracted weather information from the data

  const airTemperature = currentWeather?.data?.timelines.minutely[0]?.values?.temperature;

  const windSpeed = currentWeather?.data?.timelines.minutely[0]?.values?.windSpeed;

  const relativeHumidity = currentWeather?.data?.timelines.minutely[0]?.values?.humidity;

  const cloudAreaFraction = currentWeather?.data?.timelines.minutely[0]?.values?.cloudCover;

  const precipitationAmount =
    currentWeather?.data?.timelines.minutely[0]?.values?.precipitationProbability;
  // @ts-ignore
  const updatedAt = weatherData?.properties?.meta?.updated_at;

  // extracted units for the data
  const airTemperatureUnit = "°C";
  const windSpeedUnit = "m/s";
  const precipitationAmountUnit = "mm";
  const relativeHumidityUnit = "%";
  const cloudAreaFractionUnit = "%";

  const weatherDetails = React.useMemo(
    () => ({
      airTemperature,
      windSpeed,
      precipitationAmount,
      relativeHumidity,
    }),
    [airTemperature, windSpeed, precipitationAmount, cloudAreaFraction, relativeHumidity]
  );

  useEffect(() => {
    if (weatherDetails) {
      weatherInformation(weatherDetails);
    }
  }, [weatherDetails]);

  const WeatherDataCell = ({
    icon,
    label,
    value,
    unit,
  }: {
    icon: ReactElement;
    label: string;
    value: number | undefined;
    unit: string;
  }) => {
    return (
      <Col xs={12} md={6} lg={3}>
        <div className="d-flex align-items-center justify-content-md-center ">
          <div className="d-flex flex-wrap align-items-center border border-black my-4 p-3">
            <div className="d-flex flex-column align-items-center text-nowrap">
              {icon}
              <span className="weather-cell">{label}</span>
            </div>
            <span className="ps-2 fw-bold">
              {value} {unit}
            </span>
          </div>
        </div>
      </Col>
    );
  };

  return (
    <Container
      fluid
      className="mt-5"
      style={{
        // border: "1.5px solid silver",
        borderRadius: "5px",
        fontWeight: "normal",
        backgroundImage: `url(${weatherBackground})`,
        backgroundSize: "cover", // Adjust as needed
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "3rem",
        gap: "2rem",
      }}
    >
      {weatherData ? (
        <>
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.52)",
              width: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "1rem 1rem 2rem 1rem",
              borderRadius: "2px",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img src={thermometer} alt="temperature" />
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ color: "white", fontWeight: "500" }}>{airTemperature}</h1>
                <span style={{ color: "white", fontSize: "2rem" }}>{airTemperatureUnit}</span>
              </div>
            </div>

            <Row className="justify-content-between w-100">
              <WeatherDataCell
                icon={<FaWind size={48} />}
                label="हावाको गती"
                value={windSpeed}
                unit={windSpeedUnit}
              />
              <WeatherDataCell
                icon={<FaUmbrella size={48} />}
                label="आद्रता"
                value={relativeHumidity}
                unit={relativeHumidityUnit}
              />
              <WeatherDataCell
                icon={<FaCloud size={48} />}
                label="बादल"
                value={cloudAreaFraction}
                unit={cloudAreaFractionUnit}
              />
              <WeatherDataCell
                icon={<FaWater size={48} />}
                label="वर्षा"
                value={precipitationAmount}
                unit={precipitationAmountUnit}
              />
            </Row>
          </div>

          <Container>
            <div
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.52)",
                padding: "1rem 1rem 2rem 1rem",
                borderRadius: "2px",
              }}
            >
              {todaysData &&
                todaysData.data &&
                todaysData.data.timelines &&
                Array.isArray(todaysData.data.timelines.hourly) &&
                todaysData.data.timelines.hourly.length > 0 && (
                  <div className="table-responsive mt-4 position-relative">
                    <Table responsive>
                      <thead>
                        <tr
                          style={{
                            fontWeight: "normal",
                            backgroundColor: "transparent",
                            border: "0px none transparent",
                          }}
                        >
                          <th
                            style={{
                              textAlign: "left",
                              backgroundColor: "transparent",
                              color: "rgba(49, 48, 51, 1)",
                              verticalAlign: "middle",
                              paddingLeft: 0,
                            }}
                          >
                            <LuClock3 size={24} />
                          </th>
                          {todaysData?.data?.timelines?.hourly?.map((weather: any, i: number) => (
                            <th
                              style={{
                                textAlign: "left",
                                backgroundColor: "transparent",
                                color: "rgba(49, 48, 51, 1)",
                                padding: "10px",
                                borderBottom: "1px solid lightgrey",
                              }}
                              key={i}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <p style={{ color: "gray" }}>
                                  {convertUTCToNepalTime(weather.time)}
                                </p>
                                <span> {weather.values.temperature} °</span>
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td className="weather-cell padding-10" style={{ textAlign: "left" }}>
                            <FaWind size={24} />
                          </td>
                          {todaysData?.data?.timelines?.hourly?.map((weather: any, i: number) => (
                            <td className="weather-cell padding-10" key={i}>
                              {weather.values.windSpeed} {windSpeedUnit}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <td className="weather-cell padding-10" style={{ textAlign: "left" }}>
                            <FaUmbrella size={24} />
                          </td>
                          {todaysData?.data?.timelines?.hourly?.map((weather: any, i: number) => (
                            <td className="weather-cell padding-10" key={i}>
                              {weather.values.humidity} {relativeHumidityUnit}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <td className="weather-cell padding-10" style={{ textAlign: "left" }}>
                            <FaCloud size={24} />
                          </td>
                          {todaysData?.data?.timelines?.hourly?.map((weather: any, i: number) => (
                            <td className="weather-cell padding-10" key={i}>
                              {weather.values.cloudCover} {cloudAreaFractionUnit}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <td className="weather-cell padding-10" style={{ textAlign: "left" }}>
                            <FaWater size={24} />
                          </td>
                          {todaysData?.data?.timelines?.hourly?.map((weather: any, i: number) => (
                            <td className="weather-cell padding-10" key={i}>
                              {weather.values.precipitationProbability} {precipitationAmountUnit}
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}
            </div>
          </Container>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
              gap: "1rem",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "1rem 2rem",
                minWidth: "25%",
                borderRadius: 5,
              }}
            >
              <p style={{ fontWeight: "600" }}>मौसम पूर्वानुमान (छ दिनको)</p>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                width: "100%",
                flex: "0 0 1",
                flexBasis: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                // flexWrap: "wrap",
                overflowX: "auto",
              }}
            >
              {filteredWeatherData.map((weather: any, i: number) => (
                <WeatherStatsCard
                  windSpeed={weather?.windSpeed?.avg}
                  cloudAreaFraction={weather?.cloudCover?.avg}
                  relativeHumidity={weather?.humidity?.avg}
                  precipitationAmount={weather?.precipitation?.avg}
                  currentTemperature={weather?.temperature?.avg}
                  curentDayAndTime={formatDayAndTime(weather?.time)}
                  minTemperature={weather.temperature?.min}
                  maxTemperature={weather.temperature?.max}
                  key={i}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <div style={{ textAlign: "center", color: "white", padding: "2rem" }}>
          <h3>मौसम डेटा उपलब्ध छैन। कृपया पुनः प्रयास गर्नुहोस्।</h3>
        </div>
      )}

      {uploading || (refreshingWeather && <LoadingOverlay />)}
    </Container>
  );
};

export default Weather;
